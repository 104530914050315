
<template>
 <div>
  <div style="position: relative; width: 100%; height: 100%; overflow: hidden">
    <div class="loading" v-show="loading">
      <a-spin size="large" />
    </div>
    <div class="search-div">
      <a-form layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.orgId" style="width: 100%" placeholder="选择部门" allowClear>
            <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.empName" style="width: 100%" placeholder="调度员" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <div class="operate-btn">
            <a-button type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button @click="resetData" class="m-l1" :loading="loading">重置</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div class="content-div">
      <div class="content" v-for="(item, index) in dataList" :key="index">
        <div class="data-title">{{ item.title.label }}</div>
        <div class="data">
          <div class="data-content" v-for="(v, i) in item.statistics" :key="i">
            <div class="data-label">{{ v.titleLabel.label }}</div>
            <div class="data-num" :style="{ color: colors[v.titleLabel?.value] }" v-show="v.titleLabel?.value === 0 || v.titleLabel?.value === 4">
              {{ v.num }}
            </div>
            <a class="data-num" :style="{ color: colors[v.titleLabel?.value] }" v-show="v.titleLabel?.value !== 0 && v.titleLabel?.value !== 4 && v.num > 0" @click="openDetail(item.title, v.titleLabel)">{{ v.num }}</a>
            <span class="data-num" v-show="v.num <= 0 && v.titleLabel?.value > 0 && v.titleLabel?.value !== 4" :style="{ color: colors[v.titleLabel?.value] }">{{ v.num }}</span>
            <div class="data-proportion" v-show="v.proportion">
              占比 {{ v.proportion || "" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-drawer width="50%" :title="tableTitle" placement="right" :bodyStyle="{ padding: '10px' }" :maskClosable="true" :closable="false" :visible="tableShow" :get-container="false" :wrap-style="{ position: 'absolute' }" @close="closeDrawer">
      <div>
        <AlertList ref="alertListRef" :searchFormData="searchForm" />
      </div>
    </a-drawer>
  </div>
 </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue"
import { getData } from "@/api/dataCenter/prescription"
import AlertList from "./alertList"
// 引入路由函数
export default {
  components: {
    AlertList,
  },
  setup (props) {
    const alertListRef = ref(null)
    const state = reactive({
      loading: false,
      tableShow: false,
      dataList: [],
      tableTitle: "",
      searchForm: {
        orgId: null,
        empName: "",
      },
      mirrorSearchForm: {},
      colors: {
        0: "#0066CC", // 正常
        1: "#7948EA", // 预警
        2: "#F47920", // 超时
        3: "#D40A0A", // 严重
        4: "#0066CC", // 总数
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      document.querySelector(".ant-page-container").style.height = "94%"
    });
    const loadData = () => {
      state.loading = true
      getData(state.searchForm).then((res) => {
        if (res.code === 10000) {
          state.dataList = []
          for (let i = 0; i < res.data.length; i++) {
            state.dataList.push({
              title: res.data[i].vehicleMonitorType,
              statistics: [],
            })
            for (let j = 0; j < res.data[i].monitorLevels.length; j++) {
              state.dataList[i].statistics.push({
                titleLabel: res.data[i].monitorLevels[j].monitorLevel,
                num: res.data[i].monitorLevels[j].monitorNum,
                proportion: res.data[i].monitorLevels[j].monitorRate + '%',
              })
            }
            state.dataList[i].statistics.unshift({
              titleLabel: { label: '总数', value: 4 },
              num: res.data[i].total,
              proportion: null
            })
          }
          setTimeout(() => {
            console.log(state.dataList);
          }, 500)
        }
      }).finally(() => { state.loading = false })
    }
    const searchData = () => {
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const openDetail = (item, v) => {
      state.tableShow = true
      state.tableTitle = item.label + '-' + v.label + "列表"
      setTimeout(() => {
        alertListRef.value.setData()
        alertListRef.value.loadData(item, v)
      }, 10)
    }
    const closeDrawer = () => {
      state.tableShow = false
      setTimeout(() => {
        alertListRef.value.closeWindow()
      }, 10)
    }
    return {
      ...toRefs(state),
      alertListRef,
      searchData,
      resetData,
      openDetail,
      closeDrawer
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';

:deep(.ant-page-container  ) {
  height: 100%;
}
</style>
